import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { requestPermission, onMessageListener } from './firebase';
import { ReactNotifications,Store } from 'react-notifications-component'
function Notification({playAudio}) {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const isiOS = () => {
    const userAgent = window.navigator.userAgent;
    console.log( /iPad|iPhone|iPod/.test(userAgent) , !window.MSStream)
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  };
  useEffect(()=>{
    if(!isiOS())
    requestPermission();
  },[])
  useEffect(() => {
    let strToken = window.localStorage.getItem("strToken");
    if(strToken && !isiOS()){
      const unsubscribe = onMessageListener().then((payload) => {
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
        });
       // const path = window.location.pathname;
        if(playAudio)
          playAudio()
        Store.addNotification({
          title: `${payload?.notification?.title}`,
          message:  payload?.notification?.body,
          type: "success",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 50000,
            onScreen: true
          }
        });
        toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
          duration: 6000, 
          position: 'top-right',  
        });
  });
  
      return () => {
        unsubscribe.catch((err) => console.log('failed: ', err));
      };
    }
  }, []);
  return (
    <div>
      <Toaster />
    </div>
  );
}
export default Notification;
