// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import axios from 'axios';
import { API_BASE_URL } from '@/config/serverApiConfig';
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  }
});
const firebaseConfig = {
  apiKey: "AIzaSyAipmLIaI3d8qN89BvRf8Jw93OQBx36fh8",
  authDomain: "moiiwash.firebaseapp.com",
  projectId: "moiiwash",
  storageBucket: "moiiwash.appspot.com",
  messagingSenderId: "133557745129",
  appId: "1:133557745129:web:fa51325e6b73e860a9c778",
  measurementId: "G-SPQ2ZRZFVD"
};

let strToken = window.localStorage.getItem("strToken");

const app = strToken?initializeApp(firebaseConfig):{};
const messaging = strToken?getMessaging(app):{};

const isiOS = () => {
  const userAgent = window.navigator.userAgent;
  console.log( /iPad|iPhone|iPod/.test(userAgent) , !window.MSStream)
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
};

export const requestPermission = () => {

  let strToken = window.localStorage.getItem("strToken");
  if(strToken && !isiOS()){
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      return getToken(messaging, { vapidKey: `BF3lgwZT7rE1yif6mp11CpffBg7udJT9P6ih7mJhK8JfPLpieVRl5qmx7eVhFhjf5ZmF4bfygOoMaXk9_KbA5xs` })
        .then(async (strFcmToken) => {
          if (strFcmToken) {
            try {
              let strToken = window.localStorage.getItem("strToken");
              let strOldFCMToken = window.localStorage.getItem('strFcmToken');
              if (strOldFCMToken != strFcmToken && strToken) {
                const response = await axiosInstance.post('update_user', { strFcmToken }, {
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': strToken
                  }
                });
                window.localStorage.setItem('strFcmToken', strFcmToken);
              }
            } catch (err) {

            }
          } else {

            console.log('Failed to generate the app registration token.');
          }
        })
        .catch((err) => {

          console.log('An error occurred when requesting to receive the token.', err);
        });
    } else {

      console.log("User Permission Denied.");
    }
  });
  }

}

requestPermission();

export const onMessageListener = () =>
  new Promise((resolve) => {
    if(!isiOS())
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
    else{
      console.log('IOS problem')
    resolve();}
  });
