
import React from 'react';
// import {
//   DesktopOutlined,
//   SettingOutlined,
//   CustomerServiceOutlined,
//   FileTextOutlined,
//   FileSyncOutlined,
//   DashboardOutlined,
//   TeamOutlined,
//   UserOutlined,
//   CreditCardOutlined,
//   BankOutlined,
// } from "@ant-design/icons";

// export const IconMenu = ({ name }) => {
//   const components = {
//     DesktopOutlined: DesktopOutlined,
//     SettingOutlined: SettingOutlined,
//     CustomerServiceOutlined: CustomerServiceOutlined,
//     FileTextOutlined: FileTextOutlined,
//     FileSyncOutlined: FileSyncOutlined,
//     DashboardOutlined: DashboardOutlined,
//     TeamOutlined: TeamOutlined,
//     UserOutlined: UserOutlined,
//     CreditCardOutlined: CreditCardOutlined,
//     BankOutlined: BankOutlined,
//     Default: DesktopOutlined,
//   };

//   const IconTag = components[name || "Default"] || SettingOutlined;
//   return <IconTag />;
// };

export const routesConfig = [
  {
    path: '/',
    component: 'Dashboard',
  },
  {
    path: '/packages',
    component: 'Package',
  },  
  {
    path: '/booking',
    component: 'BookingList',
  },

  {
    path: '/vehicle',
    component: 'Vehicle',
  },
  {
    path: '/location',
    component: 'Location',
  },
  {
    path: '/doctors',
    component: 'Doctor',
  },
  {
    path: '/device_kit',
    component: 'DeviceKit',
  },
  {
    path:'/hospital',
    component:'Hospital'
  },
  {
    path:'/test',
    component:'Test'
  },
  {
    path:'/notification',
    component:'Notification'
  },
  {
    path:'/test_results',
    component:'TestResult'
  },
  {
    path:'/attendance',
    component:'Attendance'
  },
  {
    path:'/employee',
    component:'Employee'
  },
  {
    path:'/unit',
    component:'Unit'
  },
  {
    path:'/customer',
    component:'Customer'
  },
  {
    path:'/notifications',
    component:'Notifications'
  },
  {
    path:'/expense',
    component:'Expense'
  },
  {
    path: '/offer',
    component: 'Offer'
  },
  {
    path:'/coinDetails',
    component:'CoinDetails'
  }, 
  {
    path:'/subscription',
    component:'Subscription'
  },
  {
    path:'/accounts',
    component:'Account'
  },
  {
    path:'/transaction',
    component:'Transaction'
  }
 
];
