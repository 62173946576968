import React from 'react';
import { useDispatch } from 'react-redux';

import { Avatar, Menu, Dropdown } from 'antd';
import api from '@/request/services';

import {
  AppstoreOutlined,
  SettingOutlined,
  MailOutlined,
  LogoutOutlined,
  BellOutlined,
} from '@ant-design/icons';
import photo from '@/style/images/photo.png';

import { logout } from '@/redux/auth/actions';
import history from '@/utils/history';

import Notification from '../../firbase/Notification'
import NotificationSound from '../../firbase/notification.mp3'
import uniqueId from '@/utils/uinqueId';
import { useState ,useRef,useEffect} from 'react';
import MoiiWashMain from '@/style/images/avatar.png';

import io from 'socket.io-client'; 
let strToken = window.localStorage.getItem('strToken');

// const socket = io('https://chat.be.moii.ae', {
//   query: {
//     token: strToken,
//   },
// });
export default function HeaderContent() {

  const audioPlayer = useRef(null);

  function playAudio() {
    let isRep = true;
       audioPlayer.current.play().then(()=>{
        isRep = false;
       }).catch((error) => {
        isRep = true;
        console.error('Autoplay error:', error);
      });
       if(isRep)
       audioPlayer.current.play().then(()=>{
        isRep = false;
       });
    }
  

  const profileDropdown = (
    <div className="profileDropdown whiteBox shadow" style={{ minWidth: '200px' }}>
      <div className="pad15">
        <Avatar size="large" className="last" src={MoiiWashMain} style={{ float: 'left' }} />
        <div className="info">
          <p className="strong">Moiwash</p>
          <p>Admin@gmail.com</p>
        </div>
      </div>
      <div className="line"></div>
     
      <div className="line"></div>
      <div>
        <Menu>
          <Menu.Item
            icon={<LogoutOutlined />}
            key={`${uniqueId()}`}
            onClick={() => history.push('/logout')}
          >
            logout
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
 return (
    <div className="headerIcon" style={{ position: 'absolute', right: 0, zIndex: '99' }}>
     <audio allow="autoplay" ref={audioPlayer} autoPlay>
      <source src="https://moii.s3.me-central-1.amazonaws.com/New+service+booking+1+(online-audio-converter.com).ogg" type="audio/ogg" />
    </audio>
      <Dropdown overlay={profileDropdown} trigger={['click']} placement="bottomRight">
        {/* <Badge dot> */}
        <Avatar className="last" src={MoiiWashMain} />
        {/* </Badge> */}
      </Dropdown>

      <Avatar icon={<AppstoreOutlined />} />

      <Avatar icon={<BellOutlined />} />
      <audio ref={audioPlayer} autoPlay>
      <source src="https://moii.s3.me-central-1.amazonaws.com/New+service+booking+1+(online-audio-converter.com).ogg" type="audio/ogg" />

    </audio>
      <Notification playAudio={playAudio}/>
    </div>
  );
}
