export const RESET_STATE = 'CRUD_RESET_STATE';
export const CURRENT_ITEM = 'CRUD_CURRENT_ITEM';

export const REQUEST_LOADING = 'CRUD_REQUEST_LOADING';
export const REQUEST_SUCCESS = 'CRUD_REQUEST_SUCCESS';
export const REQUEST_FAILED = 'CRUD_REQUEST_FAILED';

export const CURRENT_ACTION = 'CRUD_CURRENT_ACTION';
export const RESET_ACTION = 'CRUD_RESET_ACTION';

export const CURRENT_CHAT = 'CRUD_CURRENT_CHAT'
