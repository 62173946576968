import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Drawer, Layout, Menu } from 'antd';

import { useAppContext } from '@/context/appContext';
import logoIcon from '@/style/images/logo-icon.png';
import logoText from '@/style/images/logo-text.png';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import Moiwash from '@/style/images/moii-main.png';


import {
  MenuOutlined,
  SettingOutlined,
  DashboardOutlined,
  // ClockCircleOutlined,
  UsergroupAddOutlined,
  TeamOutlined,
  CarOutlined,
  UserOutlined,
  BellOutlined,
  GiftOutlined,
  DollarCircleOutlined,
  UserSwitchOutlined,
  EnvironmentOutlined,
  BoxPlotOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  BookOutlined,
  BankOutlined,
  ReadOutlined,
  ProjectFilled,
  ProjectOutlined,
  SolutionOutlined,
  DoctorOutline,
  MonitorOutlined,
  NotificationOutlined,
  TransactionOutlined,
  // UsergroupAddOutlined,
} from '@ant-design/icons';
import { ReportOutlined } from '@material-ui/icons';
import { ManageAccountsOutlined } from '@mui/icons-material';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function Navigation() {
  return (
    <>
      <div className="sidebar-wraper">
        <Sidebar collapsible={true} />
      </div>
      <MobileSidebar />
    </>
  );
}

function Sidebar({ collapsible }) {
  const { state: stateApp, appContextAction } = useAppContext();
  const { isNavMenuClose } = stateApp;
  const { navMenu } = appContextAction;
  const [showLogoApp, setLogoApp] = useState(isNavMenuClose);

  useEffect(() => {
    if (isNavMenuClose) {
      setLogoApp(isNavMenuClose);
    }
    const timer = setTimeout(() => {
      if (!isNavMenuClose) {
        setLogoApp(isNavMenuClose);
      }
    }, 200);
    return () => clearTimeout(timer);
  }, [isNavMenuClose]);
  const onCollapse = () => {
    navMenu.collapse();
  };

  return (
    <>
      <Sider
        collapsible={collapsible}
        collapsed={collapsible ? isNavMenuClose : collapsible}
        onCollapse={onCollapse}
        className="navigation"
      >
        <div className="logo">
          {/* <img
            src={logoIcon}
            alt="Logo"
          // style={{ margin: "0 auto 40px", display: "block" }}
          /> */}

          {!showLogoApp ? (
            <img src={Moiwash} alt="Logo" style={{ width: '80px', marginTop: '3px', marginLeft: '10px' }} />
          ) : (
            <img
              src={Moiwash}
              alt="Logo"
              width={'40px'}
              style={{ margin: "30px auto 0px", display: "block" }}
            />
          )}
        </div>
        <Menu mode="inline">
          <Menu.Item key={'Dashboard'} icon={<DashboardOutlined />}>
            <Link to={'/'} />
            Dashboard
          </Menu.Item>

          <Menu.Item key={'Booking'} icon={< BookOutlined />} onClick={onCollapse}>
            <Link to={'/booking'} />
            Booking
          </Menu.Item>

          <Menu.Item key={'Customer'} icon={<UserOutlined />} >
            <Link to={'/customer'} />
            Customers
          </Menu.Item>
          <Menu.Item key={'Notifications'} icon={<BellOutlined />} >
            <Link to={'/notifications'} />
            Notifications
          </Menu.Item>
          <Menu.Item key={'SubscriptionPlan'} icon={<SolutionOutlined />} >
            <Link to={'/subscription'} />
            Subscription Plans
          </Menu.Item>
          <Menu.Item key={'Package'} icon={<ProjectOutlined />}>
            <Link to={'/packages'} />
            Packages
          </Menu.Item>
          <SubMenu key={'MASTER'} icon={<ProjectFilled />} title={'Master'}>
            <Menu.Item key={'Vehicles'} icon={< CarOutlined />}>
              <Link to={'/vehicle'} />
              Vehicles
            </Menu.Item>

            <Menu.Item key={'Locations'} icon={< EnvironmentOutlined />}>
              <Link to={'/location'} />
              Locations
            </Menu.Item>

            <Menu.Item key={'Employee'} icon={<TeamOutlined />} >
              <Link to={'/employee'} />
              Employee
            </Menu.Item>
            <Menu.Item key={'Units'} icon={< AppstoreOutlined />}>
              <Link to={'/unit'} />
              Units
            </Menu.Item>
            <Menu.Item key={'Offer'} icon={<GiftOutlined />} >
              <Link to={'/offer'} />
              Offer
            </Menu.Item>
            {/* <Menu.Item key={'holiday'} icon={<CalendarOutlined />}>
              <Link to={'/holiday'} />
              Holiday
            </Menu.Item> */}
            <Menu.Item key={'Coin Details'} icon={< AppstoreOutlined />}>
              <Link to={'/coinDetails'} />
              Coin Details
            </Menu.Item>
            <Menu.Item key={'Accounts'} icon={< ManageAccountsOutlined />}>
              <Link to={'/accounts'} />
              Accounts
            </Menu.Item>

            <Menu.Item key={'Transaction'} icon={<TransactionOutlined />} >
              <Link to={'/transaction'} />
              Transaction 
            </Menu.Item>

            <Menu.Item key={'Expense'} icon={<DollarCircleOutlined />} >
              <Link to={'/expense'} />
              Expense
            </Menu.Item>

          </SubMenu>
        </Menu>
      </Sider>
    </>
  );
}

function MobileSidebar() {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <>
      <Button type="text" size="large" onClick={showDrawer} className="mobile-sidebar-btn">
        <MenuOutlined />
      </Button>
      <Drawer
        width={200}
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
        className="mobile-sidebar-wraper"
      >
        <Sidebar collapsible={false} />
      </Drawer>
    </>
  );
}
